<template>
  <tr class="item-resume">
    <router-link
      :to="isLoggedIn ?
        '/profiles/' +
        candidate.id +
        '/' +
        candidate.firstName +
        '-' +
        candidate.occupation
          .replace(/[\s\/-]+/g, '-')
          .toLowerCase()
        : '#'
      "
      :data-rel="!isLoggedIn && 'loginModal'"
    >
      <td class="prevent-select">
        <div class="loop-item-wrap">
          <div class="item-featured">
            <img
              alt=""
              :src="!isPicError && candidate.pictureUrl200 && candidate.pictureUrl200 !== 'none' && candidate.pictureUrl200 !== 'undefined' ? `${basePicUrl}/${candidate.pictureUrl200.split('=').slice(-1)[0]}` : '/images/avatar/user_default.png'"
              @error="() => {isPicError = true}"
              class="photo"
              height="40"
              width="40"
            />
          </div>
          <div class="loop-item-content">
            <h2 class="loop-item-title">
              <span v-if="isFav" class="fav-star fa fa-star"></span>
              <span v-if="isViewed" class="viewed-icon fa fa-check pr-1"></span>
              <span
                >{{ candidate.firstName }} •
                {{ candidate.occupation || capitalize }}</span
              >
            </h2>
            <span v-if="candidate.location" class="meta-item">
              <i class="fa fa-map-marker"></i>&nbsp;<em>{{
                candidate.location
              }}</em>
            </span>
            <span v-if="candidate.jobTitle" class="meta-item">
              <i class="fa fa-briefcase"></i>&nbsp;<em>{{
                candidate.jobTitle
              }}</em>
            </span>
            <span v-if="candidate.sector" class="meta-item">
              <i class="fa fa-university"></i>&nbsp;<em>{{
                candidate.sector
              }}</em>
            </span>
            <span v-if="candidate.experience" class="meta-item">
              <i class="fa fa-clock-o"></i>&nbsp;<em>{{
                candidate.experience
              }}</em>
            </span>
          </div>
        </div>
      </td>
    </router-link>
  </tr>
</template>

<script>
export default {
  props: {
    candidate: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isPicError: false,
    }
  },

  computed: {
    basePicUrl() {
      return this.$root.$options.basePicUrl;
    },
    primaryColor() {
      return this.$root.$options.primaryColor;
    },
    isViewed() {
      return (
        this.$root.$data.user &&
        this.$root.$data.user.viewedResumes.find(
          (a) => a.candidateId === this.candidate.id
        )
      );
    },
    isFav() {
      return (
        this.isViewed &&
        this.$root.$data.user.viewedResumes.find(
          (a) => a.candidateId === this.candidate.id
        ).isFav
      )
    },
    isLoggedIn() {
      return !!this.$root.$data.token
    }
  },

  filters: {
    capitalize(value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
};
</script>

<style lang="scss">
.resumes {
  .posts-loop-content {
    table tbody td {
      width: 20%;

      &:first-child {
        width: 40%;
        padding-top: 16px;
      }
    }
  }

  .item-resume {
    .meta-item {
      margin-right: 10px;
    }

    

    .viewed-icon,
    .fav-star {
      color: v-bind(primaryColor);
    }

    a:hover h2 {
      color: v-bind(primaryColor);

      & > span {
        color: v-bind(primaryColor);
      }
    }
  }
}
</style>