<template>
  <div
    class="memberModalLogin modal fade"
    id="userloginModal"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-member">
      <div class="modal-content">
        <div class="modal-header">
          <button
            id="closeLoginModal"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 class="modal-title">Connexion</h4>
        </div>
        <div class="modal-body">
          <div class="mb-2 text-center">
            Connectez vous avec votre<br/><strong class="text-primary">compte recruteur OnlySales</strong>
          </div>
          <form class="noo-ajax-login-form form-horizontal" @submit.prevent="login">
            <div class="form-group row">
              <label class="col-sm-3 control-label">Adresse email</label>
              <div class="col-sm-9">
                <input
                  type="text"
                  class="log form-control"
                  name="log"
                  required
                  placeholder="Adresse email"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">Mot de passe</label>
              <div class="col-sm-9">
                <input
                  type="password"
                  class="pwd form-control"
                  required
                  value=""
                  name="pwd"
                  placeholder="Mot de passe"
                />
              </div>
            </div>
            <div v-if="error" class="alert alert-danger" role="alert">
              {{error}}
            </div>
            <div class="form-actions form-group text-center">
              <div class="cgu">
                En cliquant sur Connexion j'accepte les <router-link 
                  to="/terms"
                  data-dismiss="modal"
                  >CGU Onlysales</router-link>
              </div>
              <button v-if="!isLoading" type="submit" class="btn btn-primary">Connexion</button>
              <i v-else class="fa fa-spinner fa-spin"></i>
              <div class="login-form-links">
                <span
                  ><a href="https://www.onlysales.fr/membre-2/forgot-password/" target="_blank"
                    ><i class="fa fa-question-circle"></i> Mot de passe oublié ?</a
                  ></span
                >
                <span>
                  Vous n'avez pas de compte Onlysales ?&nbsp;
                  <a
                    href="https://www.onlysales.fr/membre-2/?action=login&mode=register"
                    target="_blank"
                    class="member-register-link"
                  >
                    S'inscrire <i class="fa fa-long-arrow-right"></i>
                  </a>
                </span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      isLoading: false,
      error: '',
    }
  },
  methods: {
    async login(event) {
      if (this.isLoading) return
      this.isLoading = true
      
      try {
        await this.$root.login({
          email: event.target.log.value,
          password: event.target.pwd.value,
        })

        document.getElementById('closeLoginModal').click()
        this.error = ''
      } catch (error) {
        console.log(error)
        // if 400 display message
        if (error.response?.status === 400) {
          this.error = 'Votre email ou mot de passe est incorrect'
        } else {
          this.error = 'Une erreur est survenue'
        }
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss">
#userloginModal {
  .cgu {
    margin-top: 0;
    font-size: 12px;
    position: relative;
    top: -36px;
    height: 0;
  }
}
</style>