<template>
  <div class="mt-4">
    <div class="colophon wigetized">
      <div class="container-boxed max">
        <div class="row">
          <div class="col-sm-3">
            <div class="widget widget_text">
              <h4 class="widget-title">Profils</h4>
              <div class="textwidget">
                <p><a href="https://onlysales.fr/post-resume" target="_blank">Publier un profil</a></p>
                <p><a href="https://onlysales.fr/jobs" target="_blank">Consulter les offres</a></p>
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="widget widget_text">
              <h4 class="widget-title">Recruteurs</h4>
              <div class="textwidget">
                <p><a href="https://onlysales.fr/poster-un-emploi" target="_blank">Publier une offre</a></p>
                <p><router-link to="/profiles">Voir les profils</router-link></p>
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="widget mailchimp-widget">
              <h4 class="widget-title">Contactez nous</h4>
              <p>Email : hello@onlysales.fr</p>
              <p>Tel : 07 56 95 69 12</p>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="widget widget_text">
              <p style="margin-bottom: 32px">
                <a href="./">
                  <img
                    class="noo-logo-img"
                    src="/images/logo-profiles-light.svg"
                    alt=""
                  />
                </a>
              </p>
              <div class="social-icons">
                <a class="facebook" href="https://www.facebook.com/Onlysales-110274228218625" target="_blank">
                  <i class="fa fa-facebook"></i>
                </a>
                <a class="linkedin" href="https://www.linkedin.com/company/onlysalesofficiel" target="_blank">
                  <i class="fa fa-linkedin"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="colophon site-info">
      <div class="container-full">
        <div class="footer-more">
          <div class="container-boxed">
            <div class="row">
              <div class="col-md-12">
                <div class="noo-bottom-bar-content">
                  Onlysales.fr Made with 
                  <i class="fa fa-heart text-primary"></i>
                  in Paris - 
                  <router-link to="/terms">Conditions Générales d'Utilisation</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.colophon.wigetized > .container-boxed > div > div {
  padding-top: 50px;
  padding-bottom: 50px;
}

.colophon.site-info .footer-more {
  padding-top: 20px;
  padding-bottom: 20px;
}

.colophon.wigetized p,
.colophon.wigetized a {
  color: #b6b4b4;
}


.social-icons a {
  font-size: 20px;
  padding-right: 15px;
  margin-right: 15px;
  border-radius: 50%;
  background-color: #4a5155;
  width: 40px;
  height: 40px;
  display: block;
  float: left;
  text-align: center;
  line-height: 40px;
}

.social-icons a i {
  line-height: 40px;
  width: 40px;
  height: 40px;
  color: rgba(255,255,255,0.8);
}

.footer-more .noo-bottom-bar-content, .footer-more .noo-bottom-bar-content a {
  color: #b6b4b4;
}
  
</style>