var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tr',{staticClass:"item-resume"},[_c('router-link',{attrs:{"to":_vm.isLoggedIn ?
      '/profiles/' +
      _vm.candidate.id +
      '/' +
      _vm.candidate.firstName +
      '-' +
      _vm.candidate.occupation
        .replace(/[\s\/-]+/g, '-')
        .toLowerCase()
      : '#',"data-rel":!_vm.isLoggedIn && 'loginModal'}},[_c('td',{staticClass:"prevent-select"},[_c('div',{staticClass:"loop-item-wrap"},[_c('div',{staticClass:"item-featured"},[_c('img',{staticClass:"photo",attrs:{"alt":"","src":!_vm.isPicError && _vm.candidate.pictureUrl200 && _vm.candidate.pictureUrl200 !== 'none' && _vm.candidate.pictureUrl200 !== 'undefined' ? `${_vm.basePicUrl}/${_vm.candidate.pictureUrl200.split('=').slice(-1)[0]}` : '/images/avatar/user_default.png',"height":"40","width":"40"},on:{"error":() => {_vm.isPicError = true}}})]),_c('div',{staticClass:"loop-item-content"},[_c('h2',{staticClass:"loop-item-title"},[(_vm.isFav)?_c('span',{staticClass:"fav-star fa fa-star"}):_vm._e(),(_vm.isViewed)?_c('span',{staticClass:"viewed-icon fa fa-check pr-1"}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.candidate.firstName)+" • "+_vm._s(_vm.candidate.occupation || _vm.capitalize))])]),(_vm.candidate.location)?_c('span',{staticClass:"meta-item"},[_c('i',{staticClass:"fa fa-map-marker"}),_vm._v(" "),_c('em',[_vm._v(_vm._s(_vm.candidate.location))])]):_vm._e(),(_vm.candidate.jobTitle)?_c('span',{staticClass:"meta-item"},[_c('i',{staticClass:"fa fa-briefcase"}),_vm._v(" "),_c('em',[_vm._v(_vm._s(_vm.candidate.jobTitle))])]):_vm._e(),(_vm.candidate.sector)?_c('span',{staticClass:"meta-item"},[_c('i',{staticClass:"fa fa-university"}),_vm._v(" "),_c('em',[_vm._v(_vm._s(_vm.candidate.sector))])]):_vm._e(),(_vm.candidate.experience)?_c('span',{staticClass:"meta-item"},[_c('i',{staticClass:"fa fa-clock-o"}),_vm._v(" "),_c('em',[_vm._v(_vm._s(_vm.candidate.experience))])]):_vm._e()])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }