<template>
  <div class="posts-loop-content resume-table">
    <table class="table">
      <tbody v-if="(!candidates || candidates.length === 0) && !isLoading">
        <tr>
          <td colspan="1" class="text-center">
            <p class="text-muted">Aucun profil ne correspond à votre recherche</p>
          </td>
        </tr>
      </tbody>
      <tbody v-else class="resume_nextajax-wrap">
        <CandidateItem v-for="candidate in candidates" :key="candidate.id" :candidate="candidate" />
      </tbody>
    </table>
  </div>
</template>

<script>
import CandidateItem from '@/components/CandidateItem'

export default {
  props: {
    candidates: {
      type: Array,
      default: () => [],
    },
    totalCount: {
      type: Number,
      default: 0,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CandidateItem,
  },
}
</script>