<template>
  <div class="widget widget_noo_advanced_search_widget">
    <h4 class="widget-title">Recherche</h4>
    <form class="widget-advanced-search" @submit.prevent="onSubmit">
      <div class="form-group">
        <label class="h5 mt-0">Mots clés</label>
        <input
          type="text"
          class="form-control"
          id="search-keyword"
          name="search"
          placeholder="Nom, prénom, job, ..."
          value=""
        />
      </div>
      <div class="form-group">
        <label class="h5" for="search-category">Fonction</label>
        <div class="advance-search-form-control">
          <select
            name="jobTitle"
            class="form-control-chosen form-control"
            id="search-category"
          >
            <option v-for="occupation in occupations" :value="occupation.value" :key="occupation.value">
              {{ occupation.text }}
            </option>
          </select>
        </div>
      </div>
      <div class="form-group">
        <label class="h5">Secteur</label>
        <div class="advance-search-form-control">
          <select
            name="sector"
            class="form-control-chosen form-control"
            id="search-sector"
          >
            <option v-for="sector in sectors" :value="sector.value" :key="sector.value">
              {{ sector.text }}
            </option>
          </select>
        </div>
      </div>
      <div class="form-group">
        <label class="h5">Région</label>
        <div class="advance-search-form-control">
          <select name="location" class="form-control-chosen form-control" id="search-location">
            <option v-for="location in locations" :value="location.value" :key="location.value">
              {{ location.text }}
            </option>
          </select>
        </div>
      </div>
      <div class="form-group">
        <label class="h5">Expérience</label>
        <div class="advance-search-form-control">
          <select
            name="experience"
            class="form-control-chosen form-control"
            id="search-experience"
          >
            <option v-for="experience in experience" :value="experience.value" :key="experience.value">
              {{ experience.text }}
            </option>
          </select>
        </div>
      </div>
      <div class="fav-filters form-group">
        <label class="h5">Disponibilité</label>
        <div class="advance-search-form-control">
          <select name="available" class="form-control-chosen form-control" id="search-available">
            <option value="all">Tous les Profils</option>
            <option value="active">Disponibles</option>
          </select>
        </div>
      </div>
      <div class="fav-filters form-group">
        <label class="h5">Enregistrés / Favoris</label>
        <div class="advance-search-form-control">
          <select name="fav" class="form-control-chosen form-control" id="search-fav">
            <option value="all">Tous les Profils</option>
            <option value="viewed">Profils enregistrés</option>
            <option value="fav">Profils favoris</option>
          </select>
        </div>
      </div>
      <div class="submit-cnt">
        <button v-if="isLoggedIn" type="button" class="btn btn-primary btn-search-submit" @click="() => onSubmit()">
          Rechercher
        </button>
        <button v-else type="button" class="btn btn-primary btn-search-submit" data-rel="loginModal">
          Rechercher
        </button>
        <button v-if="!isHomepage" type="button" class="ml-2 btn btn-secondary" @click="onResetFilters">Effacer les filtres</button>
      </div>
    </form>
  </div>
</template>

<script>

// todo: keep this updated
const jobTitles = {
  AE: 'Account Executive',
  AM: 'Account Manager',
  ADMINISTRATION: 'Administration des ventes',
  AGENT_COMMERCIAL: 'Agent Commercial',
  AGENT_IMMOBILIER: 'Agent Immobilier',
  ASSISTANT: 'Assistant commercial',
  BD: 'Business Developer',
  BDM: 'Business Development Manager',
  CHANNEL: 'Channel Manager',
  CHARGE: 'Chargé d\'affaires',
  CHEF_PROJET: 'Chef de projet',
  CHEF_SECT: 'Chef de secteur',
  CHEF: 'Chef des ventes',
  CRO: 'Chief Revenue Officer',
  CSO: 'Chief Sales Officer',
  SEDENTAIRE: 'Commercial sédentaire',
  COURTIER: 'Courtier',
  DIRECTEUR: 'Directeur commercial',
  DIRECTEUR_AGENCE: 'Directeur d\'agence',
  DIRECTEUR_CLIENTELE: 'Directeur de clientèle',
  DIRECTION_GENERALE: 'Direction générale',
  DIRECTION_MARKETING: 'Direction marketing',
  ENTREPRENEUR: 'Entrepreneur',
  HEAD: 'Head of Sales',
  INGENIEUR_AVANT: 'Ingénieur avant-vente',
  IC: 'Ingénieur Commercial',
  INGENIEUR: 'Ingénieur d\'affaire',
  IS: 'Inside Sales Specialist',
  KAM: 'Key Account Manager',
  MANAGER: 'Manager de centre de profit',
  PARTNERSHIP: 'Partnership Manager',
  PRESCRIPTEUR: 'Prescripteur commercial',
  REGIONAL: 'Regional Manager',
  RESPONSABLE: 'Responsable commercial',
  RESPONSABLE_ZONE_EXPORT: 'Responsable de zone Export',
  GRANDS_COMPTES: 'Responsable grands comptes',
  RESPONSABLE_OPERATIONNEL: 'Responsable opérationnel',
  RESPONSABLE_PARTENARIATS: 'Responsable partenariats',
  RESPONSABLE_REGIONAL: 'Responsable régional',
  RESPONSABLE_VENTE_INDIRECTE: 'Responsable vente indirecte',
  SA: 'Sales Assistant',
  SDR: 'Sales Development Representative',
  SM: 'Sales Manager',
  SOLUTION: 'Solution Specialist',
  TEAM: 'Team Leader',
  TECHNICO: 'Technico-commercial',
  VENDEUR_DETAIL: 'Vendeur de détail',
  VP: 'VP Sales',

  FONCTION_NON_COMMERCIALE: 'Fonction non commerciale',
}

const regionTitles = {
  DISTANCE: 'A distance',
  FRANCE: 'France entière',

  ARA: 'Auvergne-Rhône-Alpes',
  BFC: 'Bourgogne-Franche-Comté',
  BRE: 'Bretagne',
  CVL: 'Centre-Val de Loire',
  CORSE: 'Corse',
  GE: 'Grand-est',
  HDF: 'Hauts-de-France',
  IDF: 'Ile-de-France',
  NORM: 'Normandie',
  NA: 'Nouvelle-Aquitaine',
  OCC: 'Occitanie',
  PDL: 'Pays de la Loire',
  PACA: 'Provence-Alpes-Côte d\'Azur',
}

const sectorTitles = {
  TECH: 'Tech',
  DATA_SEC: 'Tech / Data – Securité',
  DEV: 'Tech / Développement – Programmation',
  DISTRIBUTION: 'Tech / Distribution – Support',
  HARDWARE: 'Tech / Hadware – Serveurs',
  INSTALLATION: 'Tech / Installation – Maintenance',
  SAAS: 'Tech / Logiciels Saas – Internet',
  MARKETING: 'Tech / Marketing digital – Publicité',
  PROGICIELS: 'Tech / Progiciels – ERP',
  TECH_SERVICES: 'Tech / Services informatiques – Telecom',
  AUTRE_TECH: 'Tech / Autre',

  SERVICES: 'Services',
  COMMUNICATION: 'Services / Communication – Marketing',
  CONSEIL: 'Services / Conseil – Audit',
  ENTRETIEN: 'Services / Entretien – Sécurité',
  EVENEMENTIEL: 'Services / Evenementiel – Logistique',
  FINANCE: 'Services / Finance – Comptabilité',
  FORMATION: 'Services / Formation – Education',
  HOTELLERIE: 'Services / Hotellerie – Restauration',
  IMMOBILIER: 'Services / Immobilier – Retail',
  JURIDIQUE: 'Services / Juridique – Avocats',
  RECRUTEMENT: 'Services / Recrutement – Interim',
  RELATION_CLIENTS: 'Services / Relation clients – Commercial',
  SPORTS: 'Services / Sports – Loisirs',
  AUTRE_SERVICES: 'Services / Autre',

  INDUSTRIE: 'Industrie',
  AGROALIMENTAIRE: 'Industrie / Agroalimentaire – Agriculture',
  AUTOMOBILE: 'Industrie / Automobile – Aéronautique',
  BTP: 'Industrie / BTP – Construction',
  CHIMIE: 'Industrie / Chimie – Pharmacie',
  ELECTRIQUE: 'Industrie / Electrique – Electronique',
  ENERGIE: 'Industrie / Energie – Environnement',
  INDUS_IMMOBILIER: 'Industrie / Immobilier – Meubles',
  METALURGIE: 'Industrie / Metalurgie – Plasturgie',
  TEXTILES: 'Industrie / Textiles – Habillement',
  TRANSPORT: 'Industrie / Transport – Ferroviaire',
  AUTRE_INDUSTRY: 'Industrie / Autres',
}

const experienceTitles = {
  '0-2': '0-2 ans',
  '0-2ans': '0-2 ans',
  '2-5': '3-5 ans',
  '2-5ans': '3-5 ans',
  '3-5': '3-5 ans',
  '3-5ans': '3-5 ans',
  '6-10': '6-10 ans',
  '6-10ans': '6-10 ans',
  '10+': '10 ans+',
  '10ans+': '10 ans+',
}


export default {
  props: {
    filters: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      occupations: [
        { value: '', text: 'Toutes les fonctions' },
        ...Object.values(jobTitles).map((occupation) => ({
          value: occupation,
          text: occupation,
        })),
      ],
      sectors: [
        { value: '', text: 'Tous les secteurs' },
        ...Object.values(sectorTitles).map((sector) => ({
          value: sector,
          text: sector,
        })),
      ],
      experience: [
        { value: '', text: 'Tous les niveaux' },
        ...Object.values(experienceTitles).map((experience_year) => ({
            value: experience_year,
            text: experience_year,
          })
        ),
      ],
      locations: [
        { value: '', text: 'Toutes les régions' },
        ...Object.values(regionTitles).map((location) => ({
          value: location,
          text: location,
        })),
      ],
    }
  },

  computed: {
    isLoggedIn() {
      return !!this.$root.$data.token;
    },
    isHomepage() {
      console.log('isHomepage', this.$route.path)
      return this.$route.path === '/';
    },
  },

  methods: {
    onSubmit() {
      const form = document.querySelector('.widget-advanced-search')
      const formData = new FormData(form)
      const data = Object.fromEntries(formData)

      if(data.fav === 'viewed') {
        data.isViewed = true
      } else if(data.fav === 'fav') {
        data.isFav = true
      }
      delete data.fav

      if(data.available === 'active') {
        data.isAvailable = true
      }
      delete data.available
        

      this.$emit('submit', data)
    },
    onResetFilters() {
      this.$emit('submit', {})
    },
    initFields(filters) {
      console.log('initFields', filters)
      if(filters.search) {
        document.querySelector('#search-keyword').value = filters.search
      } else {
        document.querySelector('#search-keyword').value = ''
      }

      if(filters.jobTitle) {
        document.querySelector('#search-category').value = filters.jobTitle
      } else {
        document.querySelector('#search-category').value = ''
      }

      if(filters.experience) {
        document.querySelector('#search-experience').value = filters.experience
      } else {
        document.querySelector('#search-experience').value = ''
      }

      if(filters.sector) {
        document.querySelector('#search-sector').value = filters.sector
      } else {
        document.querySelector('#search-sector').value = ''
      }

      if(filters.location) {
        document.querySelector('#search-location').value = filters.location
      } else {
        document.querySelector('#search-location').value = ''
      }

      if(filters.isFav) {
        document.querySelector('#search-fav').value = 'fav'
      } else if (filters.isViewed) {
        document.querySelector('#search-fav').value = 'viewed'
      } else {
        document.querySelector('#search-fav').value = 'all'
      }

      if(filters.isAvailable) {
        document.querySelector('#search-available').value = 'active'
      } else {
        document.querySelector('#search-available').value = 'all'
      }
    },
  },

  mounted() {
    this.initFields(this.filters)
  },
}
</script>