<template>
  <header class="noo-header" id="noo-header">
    <div class="navbar-wrapper">
      <div class="navbar navbar-default fixed-top shrinkable">
        <div class="container-boxed max">
          <div class="navbar-header">
            <h1 class="sr-only">Onlysales</h1>
            <a
              v-if="!user"
              class="navbar-toggle member-navbar-toggle collapsed"
              data-rel="loginModal"
            >
              <i class="fa fa-user"></i>
            </a>
            <a
              v-else
              class="navbar-toggle member-navbar-toggle collapsed"
              data-toggle="collapse"
              data-target=".noo-navbar-collapse"
            >
              <i class="fa fa-user"></i>
            </a>
            <router-link to="/" class="navbar-brand">
              <img
                class="noo-logo-img noo-logo-normal"
                src="/images/logo-profiles.svg"
                alt=""
              />
              <img
                class="noo-logo-mobile-img noo-logo-normal"
                src="/images/logo-profiles.svg"
                alt=""
              />
            </router-link>
          </div>
          <nav class="collapse navbar-collapse noo-navbar-collapse">
            <ul class="navbar-nav sf-menu">
              <li class="menu-item-post-btn">
                <router-link v-if="user" to="/profiles"
                  >Voir les profils</router-link
                >
                <a v-else data-rel="loginModal">Voir les profils</a>
              </li>
              <li
                v-if="!user"
                class="nav-item-member-profile login-link align-center"
              >
                <a
                  href="#"
                  class="member-links member-login-link"
                  data-rel="loginModal"
                >
                  <i class="fa fa-sign-in"></i>&nbsp;{{ $t("login.login") }}
                </a>
              </li>
              <li v-else class="nav-item-member-profile login-link">
                <a class="sf-with-ul">
                  <span class="profile-name">{{user.email.split('@')[0]}}</span>
                  <span v-if="user.resumeLimit != -1" class="profile-avatar text-primary">
                    {{Math.max(user.resumeLimit - user.resumeCount, 0)}} / {{user.resumeLimit}}
                  </span>
                </a>
                <ul class="sub-menu">
                  <li v-if="user.resumeLimit != -1">
                    <a href="https://onlysales.fr/membre-2/manage-plan/" target="_blank">
                      <i class="fa fa-user"></i>
                      Profils restants : {{Math.max(user.resumeLimit - user.resumeCount, 0)}} / {{user.resumeLimit}}
                    </a>
                  </li>
                  <li v-else>
                    <a href="https://onlysales.fr/membre-2/manage-plan/" target="_blank">
                      <i class="fa fa-user"></i>
                      Profils restants : Illimités
                    </a>
                  </li>
                  <!-- <li>
                    <router-link to="/alerts">
                      <i class="fa fa-bell"></i>
                      Alertes ({{user.alerts?.length || 0}})
                    </router-link>
                  </li> -->
                  <li>
                    <a href="https://onlysales.fr" target="_blank"
                      ><i class="fa fa-link"></i>Site principal Onlysales</a
                    >
                  </li>
                  <li>
                    <a href="#" @click="logout"
                      ><i class="fa fa-sign-out"></i>Se déconnecter</a
                    >
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "AppHeader",
  computed: {
    user() {
      return this.$root.$data.user;
    },
    primaryColor() {
      return this.$root.$options.primaryColor;
    },
  },
  methods: {
    logout() {
      this.$root.logout()
      window.history.pushState({}, "", "/")
    },
  },
};
</script>

<style lang="scss">

.navbar {
  .login-link {
    cursor: pointer;
  }

  .noo-logo-img {
    height: 40px;
  }

  @media (max-width: 991px) {
    .noo-navbar-collapse {

      .menu-item-post-btn {
        display: none;
      }

      .nav-item-member-profile {
        display: block !important;

        .sub-menu {
          display: block !important;

        }

        &:before {
          display: none;
        }
      }
    }
  }
}

</style>