<template>
  <div id="app">
    <LoginModal />
    <AddAlertModal />

    <AppHeader />
    <div style="min-height: 60vh">
      <router-view/>
    </div>
    <AppFooter />
		<a href="#" class="go-to-top hidden-print"><i class="fa fa-angle-up"></i></a>
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader'
import AppFooter from '@/components/AppFooter'
import LoginModal from '@/components/LoginModal'
import AddAlertModal from '@/components/AddAlertModal'

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter,
    LoginModal,
    AddAlertModal,
  },
}
</script>
