import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import(/* webpackChunkName: "terms" */ '../views/TermsView.vue')
  },
  {
    path: '/alerts',
    name: 'alerts',
    component: () => import(/* webpackChunkName: "alerts" */ '../views/AlertsView.vue')
  },
  {
    path: '/profiles',
    name: 'candidateList',
    component: () => import(/* webpackChunkName: "candidateList" */ '../views/CandidateListView.vue')
  },
  {
    path: '/profiles/:id',
    name: 'candidateDetails',
    component: () => import(/* webpackChunkName: "candidateDetails" */ '../views/CandidateDetailsView.vue'),
    children: [
      {
        path: 'details',
        alias: '/profiles/:id/:name',
        component: () => import(/* webpackChunkName: "candidateDetails" */ '../views/CandidateDetailsView.vue')
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
