<template>
  <div
    class="addAlertModal modal fade"
    id="addAlertModal"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-member">
      <div class="modal-content">
        <div class="modal-header">
          <button
            id="closeAddAlertModal"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 class="modal-title">Ajouter une alerte profils</h4>
        </div>
        <div class="modal-body">
          <form
            class="noo-ajax-login-form form-horizontal"
            @submit.prevent="save"
          >
            <div class="form-group row">
              <label class="col-sm-3 control-label">Adresse email</label>
              <div class="col-sm-9">
                <input
                  type="text"
                  class="log form-control"
                  name="mail"
                  required
                  placeholder="Adresse email"
                  :value="$root.user?.email || ''"
                />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 control-label">Fréquence</label>
              <div class="col-sm-9">
                <select class="form-control" name="frequency">
                  <option value="daily">Un mail par jour</option>
                  <option value="weekly">Un mail par semaine</option>
                </select>
              </div>
            </div>

            <div v-for="filter in Object.keys(displayFilters)" :key="filter">
              {{filter}}: {{displayFilters[filter] || '--'}}
            </div>

            <div class="form-actions form-group text-center">
              <button type="submit" class="btn btn-primary">Ajouter une alerte</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {createAlert} from '../api'

export default {
  data() {
    return {
      isLoading: false,
      error: '',
    }
  },
  computed: {
    filters() {
      return JSON.parse(JSON.stringify({
        experience: this.$root.$data.saveAlertFilters?.experience || undefined,
        sector: this.$root.$data.saveAlertFilters?.sector || undefined,
        location: this.$root.$data.saveAlertFilters?.location || undefined,
        jobTitle: this.$root.$data.saveAlertFilters?.jobTitle || undefined,
      }))
    },
    displayFilters() {
      return {
        'Fonction': this.filters.jobTitle,
        'Secteur': this.filters.sector,
        'Région': this.filters.location,
        'Expérience': this.filters.experience,
      }
    }
  },
  methods: {
    async save(event) {
      if (this.isLoading) return
      this.isLoading = true

      if(this.$root.$data.user.alerts.find(a => 
        a.email === event.target.mail.value &&
        a.frequency === event.target.frequency.value &&
        (!this.filters.experience || a.experience === this.filters.experience) &&
        (!this.filters.sector || a.sector === this.filters.sector) &&
        (!this.filters.location || a.location === this.filters.location) &&
        (!this.filters.jobTitle || a.jobTitle === this.filters.jobTitle)
      )) {
        window.alert('Vous avez déjà une alerte avec ces critères')
        this.isLoading = false
        return
      }

      try {
        await createAlert({
          token: this.$root.$data.token,
          alert: {
            email: event.target.mail.value,
            frequency: event.target.frequency.value,
            ...this.filters
          }
        })

        this.$root.$data.user.alerts.push({
          email: event.target.mail.value,
          frequency: event.target.frequency.value,
          ...this.filters
        })

        window.alert('Votre alerte a bien été créée')

        document.getElementById('closeAddAlertModal').click()
        this.error = ''
      } catch (error) {
        console.log(error)
        // if 400 display message
        if (error.response?.status === 400) {
          this.error = 'Votre email est incorrect'
        } else {
          this.error = 'Une erreur est survenue'
        }
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss">
#addAlertModal {
  label {
    display: block;
    width: 100%;
  }
}
</style>